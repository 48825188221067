import { useEffect, useState } from 'react';
import { Form, Input, Checkbox, Tooltip, FormInstance, Row, Select } from 'antd';
import { QuickNoteDto, EnumSelectListDto, Specialties, PatientIntakeDto, PatientIntakeSpecialtyDto, QuickNoteTypes, FacilityDto } from '@medone/medonehp-api-client';
import moment from 'moment';
import { toast } from 'react-toastify';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';

import { useAppDispatch, useAppSelector } from '../../../../../../shared/hooks';
import { selectQuickNoteTemplateForPACtoBBHandoff, selectQuickNoteTypes, selectSpecialties } from '../../../../../../shared/common/data/slice';
import { filterOptionsStartsWith, getSelectListOptions, isInRole } from '../../../../../../shared/common/helpers';
import { fetchIntakeDto } from '../../slice.patient-intakes';
import { specialtiesSelectors } from '../../slice';

import CustomDatePicker from '../../../../../../shared/common/components/CustomDatePicker';
import CustomSelect, { getEnumCustomSelectListOptions } from '../../../../../../shared/common/components/CustomSelect';
import Referrals from '../referrals';
import { selectAuth } from '../../../../../../shared/common/auth/slice';
import { AuthState } from '../../../../../../shared/common/auth/models';
import { Role } from '../../../../../../shared/common/auth/RoleAuth';
import { selectFacilities } from '../../../admin/slice.facilities';

const { TextArea } = Input;

type Props = {
    form: FormInstance;
    formPrefix?: string | null;
    quickNote: QuickNoteDto;
    patientIntakeId: number;
    hideSpecialties?: boolean;
};

const dateFormat = 'YYYY-MM-DD';

const QuickNoteForm = ({ form, formPrefix = null, quickNote, patientIntakeId, hideSpecialties = false }: Props) => {
    const dispatch = useAppDispatch();
    const quickNoteTypes = useAppSelector<EnumSelectListDto[]>(selectQuickNoteTypes);
    const renderDateLabel = () => <Tooltip title="Note will appear on this date">Date</Tooltip>;
    const allSpecialtyOptions = useAppSelector<EnumSelectListDto[]>(selectSpecialties);
    const intakeSpecialties = useAppSelector((state) => specialtiesSelectors.selectById(state.census.specialties, patientIntakeId));
    const quickNoteTemplateForPACtoBBHandoff = useAppSelector<string>(selectQuickNoteTemplateForPACtoBBHandoff);
    const facilities = useAppSelector<FacilityDto[]>(selectFacilities);
    const [intake, setIntake] = useState<PatientIntakeDto>(null);
    const [specialtyOptions, setSpecialtyOptions] = useState<EnumSelectListDto[]>(null);
    const [patientHasSpecialties, setPatientHasSpecialties] = useState<boolean>(false);
    const [isFollowup, setIsFollowup] = useState<boolean>(false);
    const [isSpecialtiesVisible, setIsSpecialtiesVisible] = useState<boolean>(true);
    const auth = useAppSelector<AuthState>(selectAuth);
    const isInAcoQualityCommitteeRole = isInRole(auth.permissions, [Role.ACO_QUALITY_COMMITTEE]);

    const [valuePlaceholder, setValuePlaceholder] = useState<string>();

    const getFormFieldName = (name: string) => {
        if (formPrefix !== null) {
            return [formPrefix, name];
        }

        return name;
    };

    const disablePrimaryDeselection = (obj: any, arr: any[]) => {
        return patientHasSpecialties && obj.id === Specialties.Primary && arr.length > 1;
    };

    const referredCallback = async (dto: PatientIntakeSpecialtyDto) => {
        const value = form.getFieldValue(getFormFieldName('specialties'));
        if (!value.includes(dto.specialty)) {
            toast.warn(`Making a referral now will require a ${dto.specialtyFormatted} provider to Acknowledge this Quick Note.`);
        }
    };

    const onQuickNoteTypeChange = (quickNoteType: number) => {
        if (quickNoteType === QuickNoteTypes.PACtoBBHandoff) {
            setValuePlaceholder(quickNoteTemplateForPACtoBBHandoff);
            setIsSpecialtiesVisible(false);
        } else {
            setValuePlaceholder('');
            setIsSpecialtiesVisible(true);
        }
    };

    const onFollowupChange = (e: CheckboxChangeEvent) => {
        setIsFollowup(e.target.checked);
        if (e.target.checked) {
            const value = form.getFieldValue(getFormFieldName('followupDate'));
            if (!value) {
                form.setFieldValue(getFormFieldName('followupDate'), moment().add(1, 'day'));
            }
        }
    };

    useEffect(() => {
        const initSpecialties = async () => {
            const intake = await dispatch(fetchIntakeDto(patientIntakeId));
            if (intake) {
                setIntake(intake);

                const now = moment();
                const patientSpecialties = intakeSpecialties.specialties?.filter((x) => x.startDate <= now && (x.endDate == null || x.endDate >= now)).map((x) => x.specialty);
                const patientHasSpecialties = !!patientSpecialties?.length;
                const patientSpecialtyOptions = allSpecialtyOptions.filter((x) => patientSpecialties.indexOf(x.id) >= 0);

                setPatientHasSpecialties(patientHasSpecialties);
                setSpecialtyOptions(patientHasSpecialties ? patientSpecialtyOptions : allSpecialtyOptions);

                if (!patientHasSpecialties) {
                    const facility = facilities.find((x) => x.id === intake.facilityId);
                    if (facility != null) {
                        const facilitySpecialtyOptions = allSpecialtyOptions.filter((x) => facility.specialties.indexOf(x.id) >= 0);

                        // Default to facilities specialties list
                        setSpecialtyOptions(facilitySpecialtyOptions);
                    }
                }

                if (patientSpecialties.length === 1) {
                    form.setFieldValue(getFormFieldName('specialties'), [patientSpecialties[0]]);
                } else if (patientSpecialties.length > 1) {
                    const primary = patientSpecialties.find((x) => x === Specialties.Primary);

                    if (primary != null) {
                        form.setFieldValue(getFormFieldName('specialties'), [primary]);
                    }
                } else {
                    // Default to facilities specialties list
                    const facility = facilities.find((x) => x.id === intake.facilityId);
                    if (facility != null) {
                        form.setFieldValue(getFormFieldName('specialties'), facility.specialties);
                    }
                }
            }
        };

        if (!hideSpecialties && allSpecialtyOptions && patientIntakeId > 0) {
            initSpecialties();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, patientIntakeId, allSpecialtyOptions, intakeSpecialties, hideSpecialties]);

    useEffect(() => {
        if (quickNote?.needsFollowUp) {
            setIsFollowup(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [quickNote]);

    useEffect(() => {
        if (form && quickNote) {
            // Needed because the component is embedded in various places, of different nesting depths, so form element name-pathing varies
            let pathArray = getFormFieldName('');

            if (Array.isArray(pathArray)) {
                pathArray.pop();
            } else {
                pathArray = [pathArray];
            }

            const nestedValues = setNestedFields(quickNote, pathArray);

            form.setFieldsValue(nestedValues);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [form, quickNote]);

    const setNestedFields = (data: any, pathArray: string[]) => {
        return pathArray.reduceRight((acc, key) => ({ [key]: acc }), data);
    };

    return (
        <>
            <Form.Item label={renderDateLabel()} name={getFormFieldName('reminderDate')} rules={[{ required: true, message: 'Note will appear on this date' }]}>
                <CustomDatePicker />
            </Form.Item>

            <Form.Item label="Type" name={getFormFieldName('quickNoteType')} className="required" rules={[{ required: true, message: 'Required' }]}>
                <CustomSelect options={getEnumCustomSelectListOptions(quickNoteTypes)} onChange={(v) => onQuickNoteTypeChange(v)} />
            </Form.Item>

            {!hideSpecialties && isSpecialtiesVisible && (
                <Row justify="space-between" align="middle" style={{ gap: '24px' }}>
                    <Form.Item
                        label="Specialties"
                        name={getFormFieldName('specialties')}
                        rules={[{ required: !hideSpecialties, message: 'Specialties is required.' }]}
                        style={{ flexGrow: '1' }}
                    >
                        <Select
                            mode="multiple"
                            showSearch
                            filterOption={filterOptionsStartsWith}
                            options={getSelectListOptions(specialtyOptions, 'name', 'id', false, null, disablePrimaryDeselection)}
                        />
                    </Form.Item>

                    <Referrals patientIntake={intake} onReferralCreatedCallback={referredCallback} />
                </Row>
            )}

            <Form.Item label="Body" name={getFormFieldName('value')} className="required" rules={[{ required: true, message: 'Required' }]}>
                <TextArea placeholder={valuePlaceholder} autoSize={{ minRows: hideSpecialties ? 4 : 12 }} />
            </Form.Item>

            <Row justify="end">
                <Form.Item name={getFormFieldName('needsFollowUp')} valuePropName="checked" className="mb-0" labelAlign="right">
                    <Checkbox onChange={onFollowupChange}>Needs follow up</Checkbox>
                </Form.Item>

                <Form.Item name={getFormFieldName('followupDate')} className="mb-1 ml-1">
                    <CustomDatePicker disabled={!isFollowup} min={moment().format(dateFormat)} />
                </Form.Item>
            </Row>

            {!quickNote.id && (
                <Row justify="end">
                    <Form.Item name={getFormFieldName('isDraft')} valuePropName="checked" className="mb-0" labelAlign="right">
                        <Checkbox>Mark as draft</Checkbox>
                    </Form.Item>
                </Row>
            )}

            {isInAcoQualityCommitteeRole && (
                <Row justify="end">
                    <Form.Item name={getFormFieldName('isSentFromAcoQualityCommittee')} valuePropName="checked" className="mb-0" labelAlign="right">
                        <Checkbox>Send from ACO Quality Committee</Checkbox>
                    </Form.Item>
                </Row>
            )}
        </>
    );
};

export default QuickNoteForm;
