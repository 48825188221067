import { createEntityAdapter, EntityState } from '@reduxjs/toolkit';
import {
    CensusDto,
    CensusFiltersDto,
    NoteDto,
    PatientIntakeDto,
    NoteTypes,
    CensusGroupDto,
    PccPatientDataDto,
    PatientDto,
    PatientNoteDto,
    PatientSearchResultsDto,
    NoteCensusDto,
    CptCodeDto,
    QuickNoteDto,
    FacilityQuickNotesDto,
    ScheduleDto,
    VisitDto,
    AdmissionDocGroupDto,
    MedicalSummaryLockStatusDto,
    MedicalSummaryDto,
    StagedNoteDto,
    FacilityStagedNotesDto,
    CensusBadgeDto,
    RequiredVisitResultDto,
    ExportInfoDto,
    ReturnToHospitalSummaryDto,
    PatientLabRecentItemDto,
    PatientLabRecentCategoryDto,
    PccProgressNoteDetailAggregationDto,
    PatientLabDto,
    PatientProviderSupplierDto,
    AwvPreventativeDto,
    PatientIntakeSpecialtyGroupedDto,
    HospiceIntakeDto,
    RecentSearchSelectedPatientIntakeDto,
} from '@medone/medonehp-api-client';

export const visitDuesAdapter = createEntityAdapter<RequiredVisitResultDto, number>({
    selectId: (item) => item.patientIntakeId,
});

export const censusBadgesAdapter = createEntityAdapter<CensusBadgeDto, number>({
    selectId: (item) => item.patientIntakeId,
});

export const specialtiesAdapter = createEntityAdapter<PatientIntakeSpecialtyGroupedDto, number>({
    selectId: (item) => item.patientIntakeId,
});

export interface NoteFaxDict {
    noteId: number;
    faxes: ExportInfoDto[];
}

export interface CensusField {
    patientId: number;
    facilityId: number;
    field: string;
    fieldValue: boolean;
}

export interface VisitButtonDict {
    patientIntakeId: number;
    isHistoryAndPhysicalButtonVisible: boolean;
    isWoundButtonVisible: boolean;
    isInitialPMRButtonVisible: boolean;
    userIsPMRProvider: boolean;
    userIsPhysicianScribe: boolean;
    isAwvButtonVisible: boolean;
    isProgressButtonVisible: boolean;
    isDischargeButtonVisible: boolean;
}

export interface PccPatientLoadingState {
    patientIntakeId: number;
    loading: boolean;
}

export interface CensusState {
    errorMessage: string;
    badges: EntityState<CensusBadgeDto, number>;
    visitDues: EntityState<RequiredVisitResultDto, number>;
    specialties: EntityState<PatientIntakeSpecialtyGroupedDto, number>;
    census: CensusGroupDto[];
    censusFilters: CensusFiltersDto;
    censusGroupsToRefresh: CensusGroupDto[];
    currentPatientIntake: PatientIntakeDto;
    currentPatientIntakeLockStatus: MedicalSummaryLockStatusDto;
    currentPatientIntakeIsLocked: boolean;
    currentPatientIntakeLockActive: boolean;
    currentStagedNote: StagedNoteDto;
    currentStagedNotes: StagedNoteDto[];
    currentStagedNotesGrouped: FacilityStagedNotesDto[];
    completedNotes: NoteDto[];
    incompleteNotes: NoteDto[];
    facilityQuickNotes: FacilityQuickNotesDto;
    quickNotes: QuickNoteDto[];
    currentNote: NoteDto;
    latestHgbA1cLab: PatientLabDto;
    latestTshLab: PatientLabDto;
    pendingMedicalSummaryChanges: boolean;
    pendingMedicalSummaryFields: MedicalSummaryDto;
    pccPatient: PccPatientDataDto;
    pccPatientLoading: PccPatientLoadingState;
    patientNoteLoading: boolean;
    patientNoteProcessing: boolean;
    patientNotes: PatientNoteDto[];
    currentPatientNote: PatientNoteDto;
    patient: PatientDto;
    searchResults: PatientSearchResultsDto;
    recentSearchSelectedPatientIntakes: RecentSearchSelectedPatientIntakeDto[];
    patientSearchLoading: boolean;
    patientSearchVisible: boolean;
    patientLabProcessing: boolean;
    dischargeModalVisible: boolean;
    noteIsReadonly: boolean;
    currentCensus: CensusDto;
    noteError: string;
    currentNoteType: NoteTypes;
    patientDrawerOpen: boolean;
    noteCensus: NoteCensusDto;
    notePrintContent?: string[];
    quickNotePrintContent?: QuickNoteDto[];
    sideNotePrintContent?: string;
    cptCodes: CptCodeDto[];
    procedureCptCodes: CptCodeDto[];
    noteFaxes: NoteFaxDict[];
    recentLabsLoading: boolean;
    recentLabs: PatientLabRecentCategoryDto[];
    recentLabTestDetails: PatientLabRecentItemDto[];
    schedules: ScheduleDto[];
    visits: VisitDto[];
    visitButtons: VisitButtonDict[];
    patientDocumentGroups: AdmissionDocGroupDto[];
    isQuickNoteVisible: number;
    patientIntakes: PatientIntakeDto[];
    createIntakeOnDischarge: PatientIntakeDto;
    pccPatientProgressNoteDetailsLoading: boolean;
    pccPatientProgressNoteDetails: PccProgressNoteDetailAggregationDto;
    returnToHospitals: ReturnToHospitalSummaryDto[];
    providersAndSuppliers: PatientProviderSupplierDto[];
    awvPreventativeItem: AwvPreventativeDto;
    patientHospiceIntakes: HospiceIntakeDto[];
    showHospiceIntake: boolean;
}

export const initialState: CensusState = {
    errorMessage: null,
    badges: censusBadgesAdapter.getInitialState({}),
    visitDues: visitDuesAdapter.getInitialState({}),
    specialties: specialtiesAdapter.getInitialState({}),
    census: [],
    censusGroupsToRefresh: [],
    censusFilters: null,
    currentPatientIntake: null,
    currentPatientIntakeLockStatus: null,
    currentPatientIntakeIsLocked: false,
    currentPatientIntakeLockActive: false,
    currentStagedNote: null,
    currentStagedNotes: [],
    currentStagedNotesGrouped: [],
    completedNotes: null,
    incompleteNotes: null,
    quickNotes: null,
    facilityQuickNotes: null,
    currentNote: null,
    latestHgbA1cLab: null,
    latestTshLab: null,
    pendingMedicalSummaryChanges: false,
    pendingMedicalSummaryFields: null,
    pccPatient: null,
    pccPatientLoading: null,
    patientNoteLoading: false,
    patientNoteProcessing: false,
    patientNotes: null,
    currentPatientNote: null,
    patient: null,
    searchResults: null,
    recentSearchSelectedPatientIntakes: null,
    patientSearchLoading: false,
    patientSearchVisible: false,
    patientLabProcessing: false,
    dischargeModalVisible: false,
    noteIsReadonly: false,
    currentCensus: null,
    noteError: null,
    currentNoteType: null,
    patientDrawerOpen: false,
    noteCensus: null,
    notePrintContent: [],
    quickNotePrintContent: [],
    sideNotePrintContent: null,
    cptCodes: [],
    procedureCptCodes: [],
    noteFaxes: [],
    recentLabsLoading: false,
    recentLabs: [],
    recentLabTestDetails: [],
    schedules: [],
    visits: [],
    visitButtons: [],
    patientDocumentGroups: [],
    isQuickNoteVisible: null,
    patientIntakes: [],
    createIntakeOnDischarge: null,
    pccPatientProgressNoteDetails: null,
    pccPatientProgressNoteDetailsLoading: false,
    returnToHospitals: [],
    providersAndSuppliers: null,
    awvPreventativeItem: null,
    patientHospiceIntakes: [],
    showHospiceIntake: false,
};
