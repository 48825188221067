import React, { useState } from 'react';
import { Card, Form } from 'antd';
import moment from 'moment';
import { QuickNoteDto, QuickNoteTypes } from '@medone/medonehp-api-client';

import QuickNoteForm from '../notes/quick-note-form';
import { useAppSelector } from '../../../../../../shared/hooks';
import { AuthState } from '../../../../../../shared/common/auth/models';
import { selectAuth } from '../../../../../../shared/common/auth/slice';
import { isInRole } from '../../../../../../shared/common/helpers';
import { Role } from '../../../../../../shared/common/auth/RoleAuth';

const PatientIntakeQuickNote = () => {
    const form = Form.useFormInstance();
    const auth = useAppSelector<AuthState>(selectAuth);
    const isAPP = isInRole(auth.permissions, [Role.ADVANCED_PRACTICE_PRACTITIONER, Role.POST_ACUTE_ADVANCED_PRACTICE_PRACTITIONER]);
    const quickNoteType = isAPP ? QuickNoteTypes.OnCall : null;
    const [newQuickNote] = useState<QuickNoteDto>(
        QuickNoteDto.fromJS(
            {
                reminderDate: moment(),
                quickNoteType: quickNoteType,
                needsFollowUp: false,
                isDraft: false,
                specialties: [],
                patientId: 0
            }
        )
    );


    return (
        <Card size="small" type="inner">
            <QuickNoteForm
                form={form}
                formPrefix="newQuickNote"
                patientIntakeId={0}
                quickNote={newQuickNote}
                hideSpecialties
            />
        </Card>
    );
};

export default React.forwardRef(PatientIntakeQuickNote);
