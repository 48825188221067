import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
    ClinicalCoordinatorTotalsDto,
    PatientIntakesClient,
    PatientIntakeDto,
    PatientLabsClient,
    PatientNotesClient,
    FileParameter,
    PatientLabDto,
    MedicalSummaryLockRequestDto,
    MedicalSummaryLockStatusDto,
    AbandonRequestDto,
    CensusClient,
    CensusBadgeRequestDto,
    CensusBadgeDto,
    AwvQueueItemDto,
    CensusBadgeByPatientIntakeIdsRequestDto,
} from '@medone/medonehp-api-client';

import { Axios } from '../../../../shared/common/http';
import { RootState, AppThunk, AppDispatch } from '../../../../shared/store';
import { handleError } from '../../../../shared/common/HandleErrors';
import { initialState, ClinicalCoordinatorState, CcTotalUpdate } from './models';
import { fetchPatient } from '../census/slice.patients';
import { fetchArrivingFroms } from '../admin/slice.arriving-froms';
import { Role } from '../../../../shared/common/auth/RoleAuth';
import { fetchPatientIntakeLockStatus } from '../census/slice.patient-intakes';
import { fetchFacilities } from '../admin/slice.facilities';
import { fetchSpecialties } from '../census/slice.specialties';
import { getLockExpirationData, isInRole } from '../../../../shared/common/helpers';

export const clinicalCoordinatorSlice = createSlice({
    name: 'clinicalCoordinator',
    initialState,
    reducers: {
        setError: (state: ClinicalCoordinatorState, action: PayloadAction<string>) => {
            state.errorMessage = action.payload;
        },

        setTotalsLoading: (state: ClinicalCoordinatorState, action: PayloadAction<boolean>) => {
            state.totalsLoading = action.payload;
        },

        setTotals: (state: ClinicalCoordinatorState, action: PayloadAction<ClinicalCoordinatorTotalsDto>) => {
            state.totals = action.payload;
            state.totalsLoading = false;
        },

        updateTotals: (state: ClinicalCoordinatorState, action: PayloadAction<CcTotalUpdate>) => {
            const newTotals = { ...state.totals } as ClinicalCoordinatorTotalsDto;

            newTotals[action.payload.field] = action.payload.data;

            state.totals = newTotals;
        },

        setMedicalSummaryIntake: (state: ClinicalCoordinatorState, action: PayloadAction<PatientIntakeDto>) => {
            state.medicalSummaryIntake = action.payload;
        },

        setMedicalSummaryIntakes: (state: ClinicalCoordinatorState, action: PayloadAction<PatientIntakeDto[]>) => {
            state.medicalSummaryIntakes = action.payload;
        },

        setMedicalSummaryIsLocked: (state: ClinicalCoordinatorState, action: PayloadAction<boolean>) => {
            state.medicalSummaryIsLocked = action.payload;
        },

        setNextMedicalSummaryIntakeId: (state: ClinicalCoordinatorState, action: PayloadAction<number>) => {
            state.nextMedicalSummaryIntakeId = action.payload;
        },

        setMedicalSummaryType: (state: ClinicalCoordinatorState, action: PayloadAction<string>) => {
            state.medicalSummaryType = action.payload;
        },

        setFacesheets: (state: ClinicalCoordinatorState, action: PayloadAction<PatientIntakeDto[]>) => {
            state.facesheets = action.payload;
        },

        setAnnualWellnessVisits: (state: ClinicalCoordinatorState, action: PayloadAction<PatientIntakeDto[]>) => {
            state.annualWellnessVisits = action.payload;
        },

        setAwvQueueItem: (state: ClinicalCoordinatorState, action: PayloadAction<AwvQueueItemDto>) => {
            state.awvQueueItem = action.payload;
        },

        applyPatientIntakeLock: (state: ClinicalCoordinatorState, action: PayloadAction<MedicalSummaryLockStatusDto>) => {
            const { patientIntakeId } = action.payload;

            if (state.medicalSummaryIntake != null && state.medicalSummaryIntake.id === patientIntakeId) {
                state.medicalSummaryLockStatus = action.payload;
                state.medicalSummaryIsLocked = true;
            }
        },

        clearPatientIntakeLock: (state: ClinicalCoordinatorState) => {
            state.medicalSummaryLockStatus = null;
            state.medicalSummaryIsLocked = false;
        },

        setBadgeData: (state: ClinicalCoordinatorState, action: PayloadAction<CensusBadgeDto>) => {
            state.badgeData = action.payload;
        },

        setBadgesData: (state: ClinicalCoordinatorState, action: PayloadAction<CensusBadgeDto[]>) => {
            state.badgesData = action.payload;
        },
    },
});

export const { setMedicalSummaryIntake, setMedicalSummaryIntakes } = clinicalCoordinatorSlice.actions;

export function fetchTotals(): AppThunk {
    return async (dispatch: AppDispatch, getState: () => RootState) => {
        const { permissions, account } = getState().auth;

        if (!isInRole(permissions, [Role.CLINICAL_COORDINATOR, Role.POST_ACUTE_ADMIN])) {
            return;
        }

        dispatch(clinicalCoordinatorSlice.actions.setTotalsLoading(true));

        const client = new PatientIntakesClient(null, Axios);

        try {
            const response = await client.getClinicalCoordinatorTotals();

            if (response.result.succeeded) {
                // Only update the count if this request was for the current provider
                if (account?.localAccountId === response.result.entity.userId) {
                    dispatch(clinicalCoordinatorSlice.actions.setTotals(response.result.entity));
                }
            } else {
                handleError(response, () => dispatch(clinicalCoordinatorSlice.actions.setError(null)));
            }
        } catch (error) {
            handleError(error, () => dispatch(clinicalCoordinatorSlice.actions.setError(error.toString())));
        }
    };
}

export function fetchMedicalSummaryIntakes(endpoint: string, scheduled = false): AppThunk {
    return async (dispatch: AppDispatch, getState: () => RootState) => {
        const client = new PatientIntakesClient(null, Axios);

        try {
            const response = await client[endpoint](scheduled);

            if (response.result.succeeded) {
                const allIntakeIds = (response.result.entity as PatientIntakeDto[]).map((x) => x.id);

                await dispatch(fetchSpecialties(allIntakeIds));

                dispatch(setMedicalSummaryIntakes(response.result.entity));
            } else {
                dispatch(setMedicalSummaryIntakes([]));
            }

            //await dispatch(fetchTotals());
        } catch (error) {
            handleError(error, () => dispatch(clinicalCoordinatorSlice.actions.setError(error.toString())));
        }
    };
}

export function fetchMedicalSummaryIntakeById(endpoint: string, intakeId: number): AppThunk {
    return async (dispatch: AppDispatch, getState: () => RootState) => {
        const client = new PatientIntakesClient(null, Axios);

        try {
            const response = await client[endpoint](intakeId);

            if (response.result.succeeded) {
                dispatch(clinicalCoordinatorSlice.actions.setMedicalSummaryIsLocked(false));
                await dispatch(fetchPatient(response.result.entity.patientId));
                await dispatch(fetchArrivingFroms());
                await dispatch(fetchPatientIntakeLockStatus(response.result.entity.id));
                await dispatch(fetchBadgeData(response.result.entity));
                await dispatch(fetchSpecialties(response.result.entity.id));

                dispatch(clinicalCoordinatorSlice.actions.setMedicalSummaryIntake(response.result.entity));
            } else {
                dispatch(clinicalCoordinatorSlice.actions.setMedicalSummaryIntake(null));
                if (response.result.message && response.result.message.includes('locked by another user')) {
                    dispatch(clinicalCoordinatorSlice.actions.setMedicalSummaryIsLocked(true));
                }
            }

            //await dispatch(fetchTotals());
        } catch (error) {
            handleError(error, () => dispatch(clinicalCoordinatorSlice.actions.setError(error.toString())));
        }
    };
}

export function fetchNextMedicalSummaryIntake(endpoint: string, scheduled = false): AppThunk {
    return async (dispatch: AppDispatch, getState: () => RootState) => {
        const client = new PatientIntakesClient(null, Axios);

        try {
            dispatch(clinicalCoordinatorSlice.actions.setMedicalSummaryIntake(null));
            dispatch(clinicalCoordinatorSlice.actions.setMedicalSummaryIsLocked(false));

            const response = await client[endpoint](scheduled);

            if (response.result.succeeded) {
                await dispatch(fetchMedicalSummaryIntakeById('getMedicalSummaryIntakeById', response.result.entity.id));
            } else if (response?.result?.message && !response.result.message.includes('end of the queue')) {
                handleError(response, () => dispatch(clinicalCoordinatorSlice.actions.setError(null)));
            }

            //await dispatch(fetchTotals());
        } catch (error) {
            handleError(error, () => dispatch(clinicalCoordinatorSlice.actions.setError(error.toString())));
        }
    };
}

export function fetchBadgeData(intake: PatientIntakeDto): AppThunk {
    return async (dispatch: AppDispatch, getState: () => RootState) => {
        const client = new CensusClient(null, Axios);

        try {
            const dto = CensusBadgeRequestDto.fromJS({
                patientIntakeIds: [intake.id],
            });

            const response = await client.getBadgeData(dto);

            if (response.result.succeeded) {
                if (response.result.entity && response.result.entity.length > 0) {
                    dispatch(clinicalCoordinatorSlice.actions.setBadgeData(response.result.entity[0]));
                }
            } else {
                handleError(response, () => dispatch(clinicalCoordinatorSlice.actions.setError(null)));
            }
        } catch (error) {
            handleError(error, () => dispatch(clinicalCoordinatorSlice.actions.setError(error.toString())));
        }
    };
}

export function fetchBadgeDataByPatientIntakeIds(dto: CensusBadgeByPatientIntakeIdsRequestDto): AppThunk {
    return async (dispatch: AppDispatch, getState: () => RootState) => {
        if (!dto.patientIntakeIds || dto.patientIntakeIds.length === 0) {
            return;
        }

        const client = new CensusClient(null, Axios);

        try {
            const response = await client.getBadgeDataByPatientIntakeIds(dto);

            if (response.result.succeeded) {
                if (response.result.entity && response.result.entity.length > 0) {
                    dispatch(clinicalCoordinatorSlice.actions.setBadgesData(response.result.entity));
                }
            } else {
                handleError(response, () => dispatch(clinicalCoordinatorSlice.actions.setError(null)));
            }
        } catch (error) {
            handleError(error, () => dispatch(clinicalCoordinatorSlice.actions.setError(error.toString())));
        }
    };
}

export function fetchFacesheets(): AppThunk {
    return async (dispatch: AppDispatch, getState: () => RootState) => {
        const { facilities, arrivingFroms } = getState().admin;
        const client = new PatientIntakesClient(null, Axios);

        try {
            const response = await client.getFaceSheets();

            if (response.result.succeeded) {
                if (facilities == null || facilities.length === 0) {
                    await dispatch(fetchFacilities());
                }

                if (arrivingFroms == null || arrivingFroms.length === 0) {
                    await dispatch(fetchArrivingFroms());
                }

                const allIntakeIds = response.result.entity.map((n) => n.id);

                await dispatch(fetchSpecialties(allIntakeIds));

                dispatch(clinicalCoordinatorSlice.actions.setFacesheets(response.result.entity));
            } else {
                dispatch(clinicalCoordinatorSlice.actions.setFacesheets([]));
            }
        } catch (error) {
            handleError(error, () => dispatch(clinicalCoordinatorSlice.actions.setError(error.toString())));
        }
    };
}

export function fetchAnnualWellnessVisits(): AppThunk {
    return async (dispatch: AppDispatch, getState: () => RootState) => {
        const { facilities, arrivingFroms } = getState().admin;
        const client = new PatientIntakesClient(null, Axios);

        try {
            const response = await client.getIntakesWithAwvDueSoon();

            if (response.result.succeeded) {
                if (facilities == null || facilities.length === 0) {
                    await dispatch(fetchFacilities());
                }

                if (arrivingFroms == null || arrivingFroms.length === 0) {
                    await dispatch(fetchArrivingFroms());
                }

                const allIntakeIds = response.result.entity.map((n) => n.id);

                await dispatch(fetchSpecialties(allIntakeIds));

                dispatch(clinicalCoordinatorSlice.actions.setAnnualWellnessVisits(response.result.entity));
            } else {
                dispatch(clinicalCoordinatorSlice.actions.setAnnualWellnessVisits([]));
            }
        } catch (error) {
            handleError(error, () => dispatch(clinicalCoordinatorSlice.actions.setError(error.toString())));
        }
    };
}

export function fetchAwvQueueItemById(patientIntakeId: number, loadPatient: boolean = true): AppThunk {
    return async (dispatch: AppDispatch, getState: () => RootState) => {
        const client = new PatientIntakesClient(null, Axios);

        try {
            const response = await client.getAwvQueueItemByPatientIntakeId(patientIntakeId);

            if (response.result.succeeded) {
                dispatch(clinicalCoordinatorSlice.actions.setAwvQueueItem(response.result.entity));

                if (loadPatient) {
                    dispatch(fetchPatient(response.result.entity.patientIntake.patientId));
                }
            } else {
                dispatch(clinicalCoordinatorSlice.actions.setAwvQueueItem(null));
            }
        } catch (error) {
            handleError(error, () => dispatch(clinicalCoordinatorSlice.actions.setError(error.toString())));
        }
    };
}

export function saveAwvQueueItem(dto: AwvQueueItemDto): AppThunk<Promise<boolean>> {
    return async (dispatch: AppDispatch, getState: () => RootState) => {
        const client = new PatientIntakesClient(null, Axios);

        try {
            const response = await client.saveAwvQueueItem(dto);

            if (!response.result.succeeded) {
                handleError(response, () => dispatch(clinicalCoordinatorSlice.actions.setError(null)));
            }

            return response.result.succeeded;
        } catch (error) {
            handleError(error, () => dispatch(clinicalCoordinatorSlice.actions.setError(error.toString())));
        }

        return false;
    };
}

export function completeAwvQueueItem(dto: AwvQueueItemDto): AppThunk<Promise<boolean>> {
    return async (dispatch: AppDispatch, getState: () => RootState) => {
        const client = new PatientIntakesClient(null, Axios);

        try {
            const response = await client.completeAwvQueueItem(dto);

            if (!response.result.succeeded) {
                handleError(response, () => dispatch(clinicalCoordinatorSlice.actions.setError(null)));
            }

            return response.result.succeeded;
        } catch (error) {
            handleError(error, () => dispatch(clinicalCoordinatorSlice.actions.setError(error.toString())));
        }

        return false;
    };
}

export function addPatientLab(dto: PatientLabDto): AppThunk<Promise<boolean>> {
    return async (dispatch: AppDispatch, getState: () => RootState) => {
        const client = new PatientLabsClient(null, Axios);

        try {
            const response = await client.post(dto);

            if (!response.result.succeeded) {
                handleError(response, () => dispatch(clinicalCoordinatorSlice.actions.setError(null)));
            }

            return response.result.succeeded;
        } catch (error) {
            handleError(error, () => dispatch(clinicalCoordinatorSlice.actions.setError(error.toString())));
        }

        return false;
    };
}

export function uploadAttachment(patientNoteId: number, file: FileParameter): AppThunk<Promise<string>> {
    return async (dispatch: AppDispatch, getState: () => RootState) => {
        const client = new PatientNotesClient(null, Axios);

        try {
            const response = await client.uploadAttachment(patientNoteId, file);

            if (response.result.succeeded) {
                // Do we need to do anything after upload?
            } else {
                handleError(response, () => dispatch(clinicalCoordinatorSlice.actions.setError(null)));
            }

            return response.result.entity;
        } catch (error) {
            handleError(error, () => dispatch(clinicalCoordinatorSlice.actions.setError(null)));
        }

        return null;
    };
}

export function completePatientIntake(dto: MedicalSummaryLockRequestDto): AppThunk {
    return async (dispatch: AppDispatch, getState: () => RootState) => {
        const client = new PatientIntakesClient(null, Axios);
        const response = await client.complete(dto);

        if (response.result.succeeded) {
            await dispatch(fetchTotals());
        } else {
            handleError(response);
        }
    };
}

export function updatePatientIntake(patientIntake: PatientIntakeDto): AppThunk<Promise<boolean>> {
    return async (dispatch: AppDispatch, getState: () => RootState) => {
        const client = new PatientIntakesClient(null, Axios);

        try {
            const response = await client.post(patientIntake);

            if (response.result.succeeded) {
                dispatch(clinicalCoordinatorSlice.actions.setMedicalSummaryIntake(response.result.entity));

                //await dispatch(fetchTotals());
            } else {
                handleError(response, () => true);
            }

            return response.result.succeeded;
        } catch (error) {
            handleError(error);
        }

        return false;
    };
}

export function abandonPatient(patientIntakeId: number, reason: string): AppThunk {
    return async (dispatch: AppDispatch, getState: () => RootState) => {
        const client = new PatientIntakesClient(null, Axios);

        try {
            const response = await client.abandon(AbandonRequestDto.fromJS({ patientIntakeId, reason }));

            if (!response.result.succeeded) {
                handleError(response, () => dispatch(clinicalCoordinatorSlice.actions.setError(null)));

                await dispatch(fetchTotals());
            }
        } catch (error) {
            handleError(error, () => dispatch(clinicalCoordinatorSlice.actions.setError(error.toString())));
        }
    };
}

let lockRefreshTimer: NodeJS.Timeout;

export function applyNextPatientIntakeLock(dto: MedicalSummaryLockStatusDto): AppThunk {
    return async (dispatch: AppDispatch, getState: () => RootState) => {
        const { account } = getState().auth;
        const { medicalSummaryIntake } = getState().clinicalCoordinator;
        const lockedByCurrentUser = dto.lockedByUserId === account?.localAccountId;
        const lockExpirationData = getLockExpirationData(dto);

        // If this lock is coming in for someone other than the current user
        if (account != null && medicalSummaryIntake?.id === dto.patientIntakeId && !lockedByCurrentUser) {
            if (lockExpirationData.expired) {
                dispatch(clinicalCoordinatorSlice.actions.clearPatientIntakeLock());
            } else {
                dispatch(clinicalCoordinatorSlice.actions.applyPatientIntakeLock(dto));

                if (lockRefreshTimer != null) {
                    clearTimeout(lockRefreshTimer);
                }

                lockRefreshTimer = setTimeout(async () => {
                    await dispatch(fetchPatientIntakeLockStatus(dto.patientIntakeId));
                }, lockExpirationData.refreshAtMs);
            }
        } else {
            dispatch(clinicalCoordinatorSlice.actions.clearPatientIntakeLock());
        }
    };
}

export const selectError = (state: RootState) => state.clinicalCoordinator.errorMessage;
export const selectTotalsLoading = (state: RootState) => state.clinicalCoordinator.totalsLoading;
export const selectTotals = (state: RootState) => state.clinicalCoordinator.totals;
export const selectMedicalSummaryIntake = (state: RootState) => state.clinicalCoordinator.medicalSummaryIntake;
export const selectMedicalSummaryIntakeLockStatus = (state: RootState) => state.clinicalCoordinator.medicalSummaryLockStatus;
export const selectMedicalSummaryIntakeIsLocked = (state: RootState) => state.clinicalCoordinator.medicalSummaryIsLocked;
export const selectMedicalSummaryIntakes = (state: RootState) => state.clinicalCoordinator.medicalSummaryIntakes;
export const selectNextMedicalSummaryIntakeId = (state: RootState) => state.clinicalCoordinator.nextMedicalSummaryIntakeId;
export const selectMedicalSummaryType = (state: RootState) => state.clinicalCoordinator.medicalSummaryType;
export const selectFacesheets = (state: RootState) => state.clinicalCoordinator.facesheets;
export const selectAnnualWellnessVisits = (state: RootState) => state.clinicalCoordinator.annualWellnessVisits;
export const selectAwvQueueItem = (state: RootState) => state.clinicalCoordinator.awvQueueItem;
export const selectBadgeData = (state: RootState) => state.clinicalCoordinator.badgeData;
export const selectBadgesData = (state: RootState) => state.clinicalCoordinator.badgesData;
export const setAwvQueueItem = (value: AwvQueueItemDto) => clinicalCoordinatorSlice.actions.setAwvQueueItem(value);

export default clinicalCoordinatorSlice.reducer;
