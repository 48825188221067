import { AdmissionDateChangeDto, ArrivingFromChangeDto, ArrivingFromLookupDto, FacilityDto, Genders, PatientIntakeDto } from '@medone/medonehp-api-client';
import { Row, Col, Tag, Modal, Form, Input } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { toast } from 'react-toastify';

import { useAppDispatch, useAppSelector } from '../../../../shared/hooks';
import { filterOptions, formatDateTime, getEnumValue, renderPatientIntakeFullName } from '../../../../shared/common/helpers';
import { selectArrivingFroms } from '../admin/slice.arriving-froms';
import { selectFacilities } from '../admin/slice.facilities';
import FacilityInfo from '../census/components/facility-info';
import { useState } from 'react';
import CustomSelect, { getCustomSelectListOptions } from '../../../../shared/common/components/CustomSelect';
import { handleError } from '../../../../shared/common/HandleErrors';
import { updateAdmissionDate, updateArrivingFrom } from '../census/slice.patient-intakes';
import SpecialtiesBadges from './specialties-badges';
import QuickNote from '../census/components/notes/quick-note';
import CustomDatePicker from '../../../../shared/common/components/CustomDatePicker';
import moment from 'moment';
import { fetchAwvQueueItemById } from '../clinical-coordinator/slice';

type Props = {
    patientIntake: PatientIntakeDto;
    showQuickNote?: boolean;
};

const InfoTags = ({ patientIntake, showQuickNote = false }: Props) => {
    const dispatch = useAppDispatch();
    const facilities = useAppSelector<FacilityDto[]>(selectFacilities);
    const arrivingFroms = useAppSelector<ArrivingFromLookupDto[]>(selectArrivingFroms);
    const [editArrivingFromVisible, setEditArrivingFromVisible] = useState<boolean>(false);
    const [isArriveFromOtherRequired, setArriveFromOtherRequired] = useState<boolean>(false);
    const [editAdmissionDateVisible, setEditAdmissionDateVisible] = useState<boolean>(false);
    const [isProcessing, setProcessing] = useState<boolean>(false);
    const [form] = Form.useForm();

    const handleUpdateArrivingFrom = async () => {
        setProcessing(true);

        try {
            const values = await form.validateFields();
            const patchedValues = ArrivingFromChangeDto.fromJS({
                ...values,
                patientIntakeId: patientIntake.id,
            });

            const result = await dispatch(updateArrivingFrom(patchedValues));
            if (result) {
                toast.success('Arriving From has been updated.');

                await dispatch(fetchAwvQueueItemById(patchedValues.patientIntakeId));

                form.resetFields();
                setEditArrivingFromVisible(false);
            }
        } catch (errors) {
            handleError(errors, () => true);
        }

        setProcessing(false);
    };

    const getArrivingFrom = () => {
        return arrivingFroms?.find((x) => x.id === patientIntake.arrivingFromLookupId);
    };

    const renderFacility = () => {
        const facility = facilities?.find((x) => x.id === patientIntake.facilityId);

        return (
            facility && (
                <Tag color="blue">
                    <div className="d-inline-flex">
                        <FacilityInfo facility={facility} />

                        <span>
                            <strong>Facility:</strong> {facility?.name}
                        </span>
                    </div>
                </Tag>
            )
        );
    };

    const handleArrivingFromChange = (e, selectedOption) => {
        setArriveFromOtherRequired(selectedOption.label === 'Other');
    };

    const renderArrivingFrom = () => {
        const arrivingFrom = getArrivingFrom();

        return (
            arrivingFrom && (
                <>
                    <Tag color="blue">
                        <strong>Arriving From:&nbsp;</strong>
                        {arrivingFrom.name === 'Other' ? patientIntake.arrivingFromLookupOther : arrivingFrom.name}
                        &nbsp;
                        <EditOutlined onClick={() => setEditArrivingFromVisible(true)} />
                    </Tag>

                    <Modal
                        title="Edit Arriving From"
                        open={editArrivingFromVisible}
                        confirmLoading={isProcessing}
                        onOk={handleUpdateArrivingFrom}
                        onCancel={() => {
                            form.resetFields();
                            setEditArrivingFromVisible(false);
                        }}
                    >
                        <Form name="arriving-from-cc-form" autoComplete="off" layout="vertical" form={form} initialValues={patientIntake}>
                            <Form.Item label="Arriving From" name="arrivingFromLookupId" rules={[{ required: true, message: 'Arriving From is required.' }]}>
                                <CustomSelect
                                    showSearch
                                    filterOption={filterOptions}
                                    options={getCustomSelectListOptions(arrivingFroms, 'name')}
                                    onChange={handleArrivingFromChange}
                                />
                            </Form.Item>

                            <Form.Item
                                label="Arriving From - Other"
                                name="arrivingFromLookupOther"
                                rules={[{ required: isArriveFromOtherRequired, message: 'Arriving From - Other is required.' }]}
                                hidden={!isArriveFromOtherRequired}
                            >
                                <Input />
                            </Form.Item>
                        </Form>
                    </Modal>
                </>
            )
        );
    };

    const handleUpdateAdmissionDate = async () => {
        setProcessing(true);

        try {
            const values = await form.validateFields();

            const patchedValues = AdmissionDateChangeDto.fromJS({
                admissionDate: moment(values.admissionDate),
                patientIntakeId: patientIntake.id,
            });

            const result = await dispatch(updateAdmissionDate(patchedValues));
            if (result) {
                toast.success('Admission Date has been updated.');

                await dispatch(fetchAwvQueueItemById(patchedValues.patientIntakeId));

                form.resetFields();
                setEditAdmissionDateVisible(false);
            }
        } catch (errors) {
            handleError(errors, () => true);
        }

        setProcessing(false);
    };

    const renderAdmissionDate = () => {
        return (
            <>
                <Tag color="blue">
                    <strong>Admission Date:</strong>
                    {formatDateTime(patientIntake.admissionDate, '', 'L')}
                    &nbsp;
                    <EditOutlined onClick={() => setEditAdmissionDateVisible(true)} />
                </Tag>

                <Modal
                    title="Edit Admission Date"
                    open={editAdmissionDateVisible}
                    confirmLoading={isProcessing}
                    onOk={handleUpdateAdmissionDate}
                    onCancel={() => {
                        form.resetFields();
                        setEditAdmissionDateVisible(false);
                    }}
                >
                    <Form name="admission-date-cc-form" autoComplete="off" layout="vertical" form={form} initialValues={patientIntake} className="mb-0">
                        <Form.Item label="Admission Date" name="admissionDate" rules={[{ required: true, message: 'Admission Date is required.' }]}>
                            <CustomDatePicker />
                        </Form.Item>
                    </Form>
                </Modal>
            </>
        );
    };

    return (
        patientIntake && (
            <Row key="cc-extra" className="cc-extra" align="middle" justify="space-between">
                <Col span={24} className="text-center">
                    <SpecialtiesBadges patientIntakeId={patientIntake.id} showSignOff />

                    <Tag color="blue">
                        <strong>Gender:</strong> {getEnumValue(Genders, patientIntake.gender)}
                    </Tag>

                    <Tag color="blue">
                        <strong>DOB:</strong> {formatDateTime(patientIntake.dateOfBirth, '', 'L', patientIntake.age)}
                    </Tag>

                    {renderFacility()}

                    {renderArrivingFrom()}

                    {renderAdmissionDate()}

                    {showQuickNote && (
                        <QuickNote
                            patientId={patientIntake.patientId}
                            facilityId={patientIntake.facilityId}
                            patientName={renderPatientIntakeFullName(patientIntake)}
                            patientIntakeId={patientIntake.id}
                        />
                    )}
                </Col>
            </Row>
        )
    );
};

export default InfoTags;
